import { CapacityUnit, EventType, type Unavailability } from '@utils/types/unavailability';

export type CapacityForm = {
  value: number | null;
  unit: CapacityUnit;
};

export type CreateModalForm = {
  start_date: Date | null;
  end_date: Date | null;
  installation_uuid: string | null;
  event_type: EventType | null;
  p_max: number | null;
  capacity: CapacityForm;
  comment: string | null;
  windmill_ids: string[];
  unavailability_level: 'windmill' | 'installation';
  impact_type: Unavailability['impact_type'];
};

export const CREATE_MODAL_FORM_FIELDS = [
  'start_date',
  'end_date',
  'installation_uuid',
  'event_type',
  'p_max',
  'capacity',
  'comment',
  'windmill_ids',
  'unavailability_level',
  'impact_type',
];
