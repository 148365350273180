import React from 'react';
import { Select } from '@GDM/forms';
import { Text } from '@GDM/Text';

export const SignInput = ({
  value,
  allowOnlineSignature,
  onChange,
  disabled,
}: {
  value: string;
  allowOnlineSignature: boolean;
  onChange: (value?: string) => void;
  disabled?: boolean;
}) => {
  const options = [{ value: 'offline', label: 'common.manuel' }];
  if (allowOnlineSignature) options.push({ value: 'online', label: 'common.e_signature' });

  return (
    <>
      <Select
        label="common.signature"
        options={options}
        id="signtype"
        selectedOption={value}
        onChange={(opt) => onChange(opt?.value)}
        classNamePrefix="select-sign-type"
        isDisabled={disabled}
      />

      {value === 'online' && <Text text="common.sign_alert" className="text-small mt-2" />}
    </>
  );
};
