import React from 'react';
import { Badge } from '@GDM/Badge';
import useTranslation from '@hooks/useTranslation';
import { CellContext } from '@tanstack/react-table';
import { Unavailability } from '@utils/types/unavailability';

export const EventTypeCell = ({ row: { original } }: React.PropsWithChildren<CellContext<Unavailability, unknown>>) => {
  const { t } = useTranslation();
  const event_type = original.event_type;
  const impact_type = original.impact_type;

  return (
    <div className="d-flex flex-column align-items-start gap-1">
      <div>{t(`monitoring.unavailabilities.${event_type}`)}</div>
      <Badge label={t(`monitoring.unavailabilities.impact_type.${impact_type}`)} />
    </div>
  );
};
