import dayjs from 'dayjs';
import { ExportFormData } from './export.types';

export const formatFormDataToQuery = (data: ExportFormData) => {
  const params = {
    // Note: cannot use formatSystemDate as it will convert to UTC
    firstdate: dayjs(data.dates.startDate).startOf('day').format('YYYY-MM-DD'),
    lastdate: dayjs(data.dates.endDate).add(1, 'day').startOf('day').format('YYYY-MM-DD'),
    step: data.sample,
    line_format: data.line_format.toString(),
    names: data.meterNames,
    books: data.books.map((book) => book.name),
    measure_types: Array.from(new Set(data.measureTypes)),
    ...(data.format && { format: data.format }),
  };

  return params;
};
