import { useMemo } from 'react';
import type { FilterType } from '@GDM/Filters/filters.types';
import { Book } from '@utils/types/book';
import type { EnergyType } from '@utils/types/common-types';
import { Installation } from '@utils/types/installation';
import { Unavailability, type EventType, type UnavailabilityStatus } from '@utils/types/unavailability';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useUnavailabilitiesInstallations } from '../utils/useUnavailabilitiesInstallations';

export const filterUnavailabilities: (
  filters: UnavailabilitiesFiltersType,
  unavailabilities: Unavailability[],
  books: Book[] | null,
) => Unavailability[] = (filters, unavailabilities, books) => {
  if (!unavailabilities) return [];

  return unavailabilities.filter((unavailability) => {
    const { energy, event, impact_type, status, source, installationName, sharing_status, windmill_ids } = filters;

    const isTheWrongEnergyType = energy !== 'all' && unavailability.installation.energy !== energy;
    const isTheWrongEventType = event.length > 0 && !event.includes(unavailability.event_type);
    const isTheWrongImpactType = impact_type.length > 0 && !impact_type.includes(unavailability.impact_type);
    const isTheWrongStatus = status.length > 0 && !status.includes(unavailability.status);
    const isTheWrongSource = source.length > 0 && !source.includes(unavailability.source);
    const isNotInBook =
      filters.bookUuid &&
      !books?.find((b) => b.uuid === filters.bookUuid)?.installation_names?.includes(unavailability.installation.name);
    const isNotInstallation =
      installationName.length > 0 && !filters.installationName.includes(unavailability.installation.name);
    const isNotSharingStatus = sharing_status && unavailability.sharing_status !== sharing_status;
    const isNotWindmills =
      windmill_ids.length > 0 &&
      !windmill_ids.some((id) => unavailability.windmills?.map((w) => w.id.toString()).includes(id));

    if (
      isTheWrongEnergyType ||
      isTheWrongEventType ||
      isTheWrongStatus ||
      isNotInBook ||
      isTheWrongSource ||
      isNotInstallation ||
      isNotSharingStatus ||
      isNotWindmills ||
      isTheWrongImpactType
    )
      return false;

    return true;
  });
};

const filterInstallations: (
  filters: UnavailabilitiesFiltersType,
  installations: Installation[] | null,
  books: Book[] | null,
) => Installation[] = (filters, installations, books) => {
  if (!installations) return [];

  return installations.filter((installation) => {
    if (filters.energy != 'all' && installation.energy != filters.energy) return false;
    if (filters.installationName.length > 0 && !filters.installationName.includes(installation.name)) return false;
    if (
      filters.bookUuid &&
      !books?.find((b) => b.uuid === filters.bookUuid)?.installation_names?.includes(installation.name)
    )
      return false;

    return true;
  });
};

export const useFilters = (books: Book[]) => {
  const { data: installations } = useUnavailabilitiesInstallations();

  const form = useForm<UnavailabilitiesFiltersType>({
    defaultValues: {
      dateRange: [dayjs().subtract(3, 'day').toDate(), dayjs().add(1, 'month').toDate()],
      energy: 'all',
      event: [],
      status: [],
      bookUuid: null,
      installationName: [],
      chartVisible: false,
      source: [],
      windmill_ids: [],
      sharing_status: null,
      impact_type: [],
    },
  });
  const { watch } = form;
  const filters = watch();

  const filteredInstallations = useMemo(
    () => filterInstallations(filters, installations || [], books),
    [filters, installations, books],
  );

  return useMemo(() => ({ form, filteredInstallations }), [filteredInstallations, form]);
};

export type UnavailabilitiesFiltersType = FilterType<{
  dateRange: [Date | null, Date | null];
  energy: EnergyType | 'all';
  event: EventType[];
  impact_type: Unavailability['impact_type'][];
  status: UnavailabilityStatus[];
  bookUuid: string | null;
  installationName: string[];
  chartVisible: boolean;
  source: Unavailability['source'][];
  sharing_status: Unavailability['sharing_status'] | null;
  windmill_ids: string[];
}>;
