import { useCallback } from 'react';
import { useRequest } from '@hooks/useRequest';
import axiosInstance from '@utils/axiosInstance';
import replaceAccents from '@utils/replaceAccents';
import { v2_export_get_meters_data_path } from '@utils/routes';
import { AxiosRequestConfig } from 'axios';
import fileDownload from 'js-file-download';
import { ExportFormData } from './export.types';
import { formatFormDataToQuery } from './formatFormDataToQuery';

const fetchExport = async ({ url, options }: { url: string; options?: AxiosRequestConfig }) => {
  const response = await axiosInstance(false).get(url, { ...options, responseType: 'blob' });
  const filename = response.headers['content-disposition'].match(/filename="([^"]+)"/)?.[1];
  const data = response.data;

  if (response.headers['content-type'] == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    fileDownload(data, replaceAccents(filename));
  else fileDownload(data, replaceAccents(filename), 'text/csv;charset=utf-8', '\uFEFF');

  return data;
};

export const useExportDownload = () => {
  const {
    data,
    error,
    loaded,
    loading,
    execute: reqExecute,
  } = useRequest(v2_export_get_meters_data_path(), fetchExport, true);

  const execute = useCallback(
    (data: ExportFormData) => {
      reqExecute?.({ params: formatFormDataToQuery(data) });
    },
    [reqExecute],
  );

  return {
    data,
    error,
    loaded,
    loading,
    execute,
  };
};
